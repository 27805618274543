import BaseCom from "./BaseCom";
import Icon from "./Icon";
import { InfoModalGlobal } from "./InfoModal";
import './InfoBadge.css';
import L from "./Lang";

export default class InfoBadge extends BaseCom {

    showModal = () => {
        InfoModalGlobal.show(this.props.title || 'About This', this.props.children);    
    }

    render() {
        return (<span role="button" className="info-badge text-muted">
            <Icon icon="faQuestionCircle" onClick={this.showModal} />
        </span>
        );
    }
}